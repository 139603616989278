import React from "react";
import "./Footer.css";
import { SocialIcon } from "react-social-icons";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-section">
        <h3>KONTAKT</h3>
        <p>
          <b>Beratung & Service</b>
          <br />
          <b>
            <i>caretaker</i>Line
          </b>
          <br /> +41 58 262 02 60
          <br /> info@roesli-caretaker.ch
        </p>
        <p>
          <b>Standort</b>
          <br /> roesli <i>caretaker</i> GmbH
          <br /> Hofstrasse 109, 8620 Wetzikon
        </p>
      </div>
      <div className="footer-section">
        <h3>WEBSEITE</h3>
        <p>
          <a href="/">Home</a>
        </p>
        <p>
          <a href="/about">Über Uns</a>
        </p>
        {/* <p>
          <a href="/inquiry">Ihre Anfrage</a>
        </p> */}
        <p>
          <a href="/contact">Kontakt</a>
        </p>
      </div>
      <div className="footer-section">
        <h3>INFORMATIONEN</h3>
        <p>
          <a href="/datenschutz">Datenschutz </a>
        </p>
        {/* <p>
          <a href="/agb"> AGB </a>
        </p> */}
        <p>
          <a href="/impressum">Impressum</a>
        </p>
        <div className="social-media">
          <SocialIcon
            url="https://linkedin.com"
            href="https://www.linkedin.com/company/roesli-caretaker-gmbh/"
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
